.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  width: 100%; /* Full width for container */
  grid-row: 2; /* Place container in the second row */
}

.thecontainer {
  display: grid;
  grid-template-rows: auto 1fr; /* Define grid rows */
  gap: 20px; /* Add space between rows */
}

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.title-container img:first-child {
  width: 100%; /* Adjust width of the first image */
}

.title-container img:last-child {
  width: 50px; /* Adjust width of the last image */
}

/* Set image height to 50% of the viewport height on large/medium devices */
img {
  width: 100%; /* Ensure full width */
  height: 40vh; /* Set image height to 40% of the viewport height */
  top: 20px;
}

/* Responsive Image for small devices */
.pic {
  height: auto;
  width: 100%; /* Full width */
  max-width: 400px; /* Optional max width for large images */
}

/* Additional Media Queries */
@media (max-width: 768px) {
  .title-container {
    flex-direction: column; /* Stack title elements vertically */
    text-align: center; /* Center text */
  }

  .title-container img {
    width: 100%; /* Ensure images take full width */
  }

  .pic {
    object-fit: contain; /* Maintain aspect ratio */
  }

  .collapsibles-table {
    grid-template-columns: 1fr; /* One column layout */
  }

  /* Adjust image height for smaller screens */
  img {
    height: 30vh; /* Reduce height for smaller devices */
  }

  iframe {
    height: auto; /* Adjust iframe height for smaller screens */
  }
}

@media (max-width: 480px) {
  .container {
    margin: 10px auto; /* Reduce margin on extra small screens */
  }

  .pic {
    max-width: 100%; /* Full width on small devices */
  }

  .title-container {
    margin-bottom: 15px; /* Reduce margin for compact layout */
  }

  /* Further adjust image height for very small screens */
  img {
    height: 25vh; /* Further reduce image height on very small screens */
  }
}
