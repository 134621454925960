
.containers {
  position: relative;
  max-width: 60%;
  margin: 0 auto;
  padding: 30px;
  text-align: center;
  border: 5px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-weight: 500;
}

/* Header and paragraph styling */
h4,
p {
  text-align: center;
  width: 100%;
}


 button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}


/* Media Query for Tablets and Smaller Screens */
@media (max-width: 768px) {

  .containers {
    position: relative;
    max-width: 90%;
    margin: 0 auto;
    padding: 12px;
    text-align: center;
    border: 5px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .checkout-form input,
  .checkout-form button {
    max-width: 100%;
    padding: 8px;
  }
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .containers {
    position: relative;
    max-width: 90%;
    margin: 0 auto;
    padding: 12px;
    text-align: center;
    border: 5px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  h4,
  p {
    font-size: 1rem;
  }
}
.fields {
  width: 100%;
}

/* Close button styles */
.closes-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  font-weight: bold;
  color: #ff0000;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.closes-icon:hover {
  transform: scale(1.2);
}
