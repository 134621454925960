/* Make sure th is full width */
.collapsibles-table {
  width: 100%;
}

.collapsible-trigger {
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px;
  background-color: #f0f0f0;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s ease-in-out;
}

.container h1{
padding-bottom: 30px;
}
/* Ensure the collapsible container stretches properly */
.title-container {
  flex: 1;
  min-width: 300px;
}

/* Rotate icon on collapse */
.Collapsible__trigger-expanded .icon {
  transform: rotate(180deg);
}

/* Icon styling */
.icon {
  margin-left: auto; /* Push icon to the right */
  transition: transform 0.3s ease-in-out;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .collapsible-section {
    flex-direction: column;
    gap: 10px;
  }
}
/* Video container maintains aspect ratio */
.video-container {
  position: relative;
  width: 100%;
  max-width: 1250px; /* Prevents video from being too wide on large screens */
  margin: 0 auto;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
}

/* Make the iframe responsive */
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

/* Responsive Design - Adjust video for different screen sizes */
@media (max-width: 1024px) { /* Medium devices */
  .video-container {
    max-width: 800px;
    padding-bottom: 56.25%; /* Maintain 16:9 ratio */
  }
}

@media (max-width: 768px) { /* Small devices */
  .video-container {
    max-width: 100%;
    padding-bottom: 56.25%; /* Maintain aspect ratio */
  }
}

@media (max-width: 480px) { /* Extra small devices */
  .video-container {
    max-width: 100%;
    padding-bottom: 75%; /* Slightly taller video for smaller screens */
  }
}
